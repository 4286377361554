<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <div class="text-right mb-1 mr-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="assignStudentsProgramme"
              >
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Gán chương trình đào tạo
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="ml-1"
                @click="downloadFile"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="DownloadIcon" /> Xuất danh sách sinh viên
                </span>
              </b-button>
            </div>
            <b-row>
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  :select-options="selectOptions"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="onSelectedRowsChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="studentClassUpdatable"
                          v-b-modal.classStudentSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chuyển lớp"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="MoveIcon" />
                        </b-button>
                      </b-button-group>
                      <b-button-group>
                        <b-button
                          style="margin-left: 3px;"
                          variant="info"
                          class="btn-icon"
                          size="sm"
                          title="Thông tin"
                          @click="onInfoData(props.row)"
                        >
                          <feather-icon icon="InfoIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <span v-else-if="props.column.field === 'rowNum'">
                      {{ (filter.currentPage-1)* filter.itemsPerPage +props.row.rowNum }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <ClassStudentSave
      ref="classStudentSaveFormRef"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-info-student"
      ref="modal-info-student"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="xl"
      title="Thông tin sinh viên"
      body-class="position-static"
      hide-footer
    >
      <CurriculumVitae
        :student-id="studentId"
        :class-id="+filter.classId"
        @succeed="onSucceed"
      />
    </b-modal>
    <StudentProgramme
      :course-id="dataSendClass.courseId"
      @save="onSaveStudentsProgramme"
    />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ClassStudentSave from '@/views/student-class/ClassStudentSave.vue'
import CurriculumVitae from '@/views/curriculum-vitae/CurriculumVitae.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { Workbook } from '@syncfusion/ej2-excel-export'
import StudentProgramme from '@/views/student-class/StudentProgramme.vue'

export const Attr = Object.freeze({
  store: 'studentClass',
})
export default {
  name: 'StudentClass',
  directives: {
    Ripple,
  },
  components: {
    StudentProgramme,
    CurriculumVitae,
    ClassStudentSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSendClass: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        classId: this.$route.params.id || this.dataSendClass.id,
        sort: '1_asc',
        name: null,
        code: null,
        status: null,
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      studentId: undefined,
      selectOptions: {
        enabled: true,
      },
      selectedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'Mã lớp',
          field: 'classCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Họ tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình đào tạo',
          field: 'programmeName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Chuyên ngành',
          field: 'majorName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Chức danh',
          field: 'titleName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    studentClassUpdatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENT_CLASS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      getAllData: `${Attr.store}/getAllData`,
      deleteMainData: `${Attr.store}/deleteData`,
      assignProgramme: `${Attr.store}/assignProgramme`,
    }),
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onInfoData(mainData) {
      this.studentId = mainData.studentId
      this.$refs['modal-info-student'].show()
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName} <span class="text-danger">${mainData.name}</span> khỏi lớp độc lập?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      const result = this.statuses.find(status => status.value === id)
      if (result) {
        return result.label
      }
      return '---'
    },

    async onSucceed() {
      await this.getMainDataFromStore()
    },

    async downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 200 },
        { index: 3, width: 130 },
        { index: 4, width: 150 },
        { index: 5, width: 170 },
        { index: 6, width: 150 },
        { index: 7, width: 100 },
        { index: 8, width: 150 },
        { index: 9, width: 150 },
        { index: 10, width: 150 },
        { index: 11, width: 150 },
        { index: 12, width: 150 },
        { index: 13, width: 150 },
        { index: 14, width: 150 },
        { index: 15, width: 150 },
        { index: 16, width: 150 },
        { index: 17, width: 150 },
        { index: 18, width: 150 },
        { index: 19, width: 150 },
        { index: 20, width: 150 },
        { index: 21, width: 150 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_SINH_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'NAME',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'MA_LOP_CO_DINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'MA_CTĐT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'KHOA_HOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'HE_DAO_TAO',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'NGAY_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'EMAIL',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'CCCD',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'DIA_CHI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'SO_BAO_HIEM',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'DAN_TOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'TON_GIAO',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 14,
            value: 'LOAI_KHUYET_TAT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 15,
            value: 'TINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 16,
            value: 'QUAN/HUYEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 17,
            value: 'QUAN/HUYEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 18,
            value: 'NOI_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 19,
            value: 'NGAY_VAO_DOAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 20,
            value: 'NGAY_VAO_DANG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 21,
            value: 'NGAY_VAO_DANG_CHINH_THUC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }

      rows.push(headerRow)
      const dataAll =  await this.getAllData({
        ...this.filter, ...{ itemsPerPage: 100000 },
      })

      dataAll.forEach((data, index) => {
        const newRow = {
          index: index + 2,
          cells: [
            {
              index: 1,
              value: data.code,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 2,
              value: data.name,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 3,
              value: data.classCode,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 4,
              value: data.programmeCode,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 5,
              value: data.courseName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 6,
              value: data.trainingSystemName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 7,
              value: data.birthday,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 8,
              value: data.email,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 9,
              value: data.identifyCode,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 10,
              value: data.address,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 11,
              value: data.socialInsurance,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 12,
              value: data.ethnicName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 13,
              value: data.religionName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 14,
              value: data.disability,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 15,
              value: data.provinceName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 16,
              value: data.districtName,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 17,
              value: "",
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 18,
              value: data.permanentResidence,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 19,
              value: data.dateYouthGroup,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 20,
              value: data.dateDangcongsanGroup,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 21,
              value: data.dateDangcongsanGroupChinhthuc,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 7,
              value: data.birthday,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },
            {
              index: 7,
              value: data.birthday,
              colSpan: 1,
              style: {
                borders: {
                  color: '#00000000',
                  lineStyle: 'thin',
                },
              },
            },













          ],
        }
        rows.push(newRow)
      })

      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')

      workbook.save('ds_sinh_vien_lop_co_dinh.xlsx')
    },
    assignStudentsProgramme() {
      if (this.selectedRows.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn sinh viên',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$bvModal.show('studentProgrammeSaveModal')
    },
    onSelectedRowsChange(params) {
      this.selectedRows = params.selectedRows
        .map(item => ({ id: item.id }))
    },
    async onSaveStudentsProgramme(programmeId) {
      this.isLoading = true
      try {
        const response = await this.assignProgramme({
          studentClasses: this.selectedRows,
          programmeId,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('studentProgrammeSaveModal')
            await this.getMainData(this.filter)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
#modal-info-student {
  .modal-dialog,
  .modal-content {
    /* 80% of window height */
    height: 72%;
  }

  .modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
  }
}
</style>
